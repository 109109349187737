.nav-container {
    outline: black solid 3px;
    margin: 0rem 0rem 1rem 0rem;
    &-tall {
        @extend .nav-container;
        height: 5rem;
        width: 100%;
    }
    &-short {
        @extend .nav-container;
        width: 100%;
        height: 3rem;
    }
}