@import "./variables";

.hack-cover {
    height: auto;
    border: 2px solid black;
    width: 400px;
    border-radius: 1rem;
    @media only screen and (max-width: $xl) {
        width: 300px;
    }
}

.homepage-background {
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('../GeneralBG.png');
    background-position: center;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}
