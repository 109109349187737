@import "./variables";

.location-content-container {
    padding: 0 1rem 0 1rem;
    border: solid black 2px;
    border-radius: 1rem;
    background-color: white;
    margin-bottom: 1rem;
    @media only screen and (max-width: $sm) {
        padding: 0 .5rem 0 .5rem;
        margin-bottom: .5rem;
    }
}

.collectible-padding {
    padding: 0 1rem 0 1rem;
}

.location-container {
    width: 97.5%;
    border: 2px solid black;
    margin: 1rem 0 1rem 0;
    border-radius: 1rem;
    background-color: lightgray;
    @media only screen and (max-width: $sm) {
        width: 95%;
        padding: 0 .5rem 0 .5rem;
        margin-bottom: .5rem;
    }
}

.location-body-container {
    width: $xl;
    padding-bottom: 3rem;
    height: fit-content;
    @media only screen and (max-width: $xl) {
        border: none;
        width: 100vw;
    }
}
