@import "./variables";

.mon-container-expanded {
    height: 10rem;
}

.mon-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 9;
    background-color: white;
    border-bottom: 2px solid black;
    height: 5rem;
    width: 1536px;
    @media only screen and (max-width: 1536px) {
        width: 100%;
    }
}

.dex-nav-sprite {
    height: 90%;
    width: auto;
}

.increment-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
}

.mon-content {
    padding-top: 5rem;
}

.display-portrait {
    width: 28rem;
    height: auto;
    @media only screen and (max-width: $sm) {
        width: 20rem;
    }
}

.portrait-container {
    position: relative;
    text-align: center;
    width: 100%;
}

.portrait-container h1 {
    position: absolute;
    top: 0;
    left: 15px;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    padding: 3px 3px 3px 3px;
    margin: 0rem 0rem 0rem 0rem;
}

.ability-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;
    width: 80%;
}

.display-type {
    width: 45%;
    height: auto;
}

.form-select-sprite {
    height: 5rem;
    width: auto;
}

.form-sprite-container {
    height: 100%;
    width: 100%;
    border: 2px solid black;
    border-radius: 0 0 1rem 1rem;
}

.selected-form {
    background-color: white;
    border-top: 2px solid white;
}

.unselected-form {
    background-color: lightgray;
}

.move-list-divider {
    border-bottom: 1px gray solid;
    margin: 3px 0 3px 0;
}

.form-select-container {
    height: 100%;
    width: 100%;
}

.ability-box {
    border: 2px black solid;
    width: 95%;
    border-radius: 75px;
    background-color: lightcyan;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
}

.chart-bar {
    height: 30px;
    margin-top: 1rem;
}

.chart-margins {
    margin-left: 10%;
    margin-top: 2rem;
}

.label-container-margins {
    margin-top: 5px;
    margin-right: 1rem;
}

.chart-base {
    height: 290px;
    width: 2px;
    background-color: black;
}

.mon-top-bar h2 {
    font-size: 36px;
    @media only screen and (max-width: 440px) {
        font-size: 24px;
    }
}

.copy-to-clipboard {
    z-index: 110000;
    position: relative;
    top: 10px;
    left: 10px;
    bottom: 0;
    color: black;
    cursor: pointer;
}

.encounter-sprite{
    height: 4rem;
    width: auto;
}

.sprite-view {
    position: absolute;
    border-radius: 30%;
    background-color: black;
    // margin: 5px 0 5px 0;
    $sprite-dimension: 5rem;
    height: $sprite-dimension;
    width: $sprite-dimension;
    z-index: 1;
    opacity: 10%;
}

.sprite-view-container {
    position: relative;
    // border-radius: 30%;
    // background-color: #eeeeee;
    margin: 5px;
    $sprite-dimension: 5rem;
    height: $sprite-dimension;
    width: $sprite-dimension;
    // fill-opacity: 50%;
    // opacity: 50%;    
    // border: 1px solid black;
    // opacity: 50%;
}
