.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-background {
  // background-color: #696969;
  // position: fixed;
  // // top: 5rem;
  // // background-image: url('../GeneralBG.png');
  // background-size: cover;
  // width: auto;
  // // width: 100vw;
  // // height: 110vh;
  // background-repeat: no-repeat;
}

@import "./shared";
@import "./topBar";
@import "./gameNav";
@import "./pokedex";
@import "./pokemon";
@import "./type";
@import "./moves";
@import "./trainers";
@import "./homepage";
@import "./encounters";
@import "./locations";
@import "./tutors";
