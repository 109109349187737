@import "./variables";
$font-size: 25px;

.top-bar {
    position: fixed;
    left: 0;
    z-index: 10;
    border-bottom: 2px solid black;
    background-color: lightgray;
    width: 100vw;
}

.top-bar-content {
    height: $top-bar-height;
}

.menu-link {
    margin: 1rem;
    // text-align: center;
}

.top-bar-padding {
    position: fixed;
    top: $top-bar-height;
    overflow-y: scroll;
}

.links-container {
    display: flex;
    align-items: center;
}

.home-icon {
    font-size: $font-size;
    margin-left: 1.5rem;
}

.menu-topbar {
    font-size: $font-size;
    margin-right: 2rem;
}

.menu-topbar a {
    text-decoration: none;
    color: black;
}

.top-bar-link {
    margin-right: 1rem;
}
.top-bar-link b {
    color: black;
}

.top-bar-selected b {
    color: darkgray;
}

.toggle-container {
    border: 2px solid black;
    border-right: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.toggle {
    border-right: 2px black solid;
    padding: 0 1rem 0 1rem;
    color: black;
    text-decoration: none;
    &-selected {
        @extend .toggle;
        background-color: white;
    }
    &-unselected {
        @extend .toggle;
        background-color: darkgray;
    }
}