@import "./variables";

.flex {
    display: flex;
}

.center {
    @extend .flex;
    align-items: center;
    justify-content: center;
}
.align-center {
    @extend .flex;
    align-items: center;
}
.align-end {
    @extend .flex;
    align-items: end;
}
.align-start {
    @extend .flex;
    align-items: start;
}
.justify-center {
    @extend .flex;
    justify-content: center;
}
.justify-right {
    @extend .flex;
    justify-content: right;
}
.justify-left {
    @extend .flex;
    justify-content: left;
}
.justify-around {
    @extend .flex;
    justify-content: space-around;
}
.justify-between {
    @extend .flex;
    justify-content: space-between;
}
.column {
    @extend .flex;
    flex-direction: column;
}

.pos-absolute {
    position: absolute;
}
.pos-relative {
    position: relative;
}

.top-1 {
    padding-top: 1rem;
}
.top-2 {
    padding-top: 2rem;
}

.width-100 {
    width: 100%;
}
.width-90 {
    width: 90%;
}
.width-80 {
    width: 80%;
}
.width-60 {
    width: 60%;
}

.invis {
    display: none;
    &-xs {
        @media only screen and (max-width: $sm) {
            display: none;
        }
    }
    &-sm {
        @media only screen and (max-width: $md) {
            display: none;
        }
    }
    &-md {
        @media only screen and (max-width: $lg) {
            display: none;
        }
    }
    &-lg {
        @media only screen and (max-width: $xl) {
            display: none;
        }
    }
}

.vis {
    &-xs {
        display: none;
        @media only screen and (max-width: $sm) {
            display: contents;
        }
    }
    &-sm {
        display: none;
        @media only screen and (max-width: $md) {
            display: contents;
        }
    }
    &-md {
        display: none;
        @media only screen and (max-width: $lg) {
            display: contents;
        }
    }
    &-lg {
        display: none;
        @media only screen and (max-width: $xl) {
            display: contents;
        }
    }
}

.text-center {
    text-align: center;
}

.background {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100vw;
}

.content-background {
    background-color: white;
    border-left: 2px solid black;
    border-right: 2px solid black;
}

.content-container {
    background-image: url('../GeneralBG.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: $top-bar-height;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
}

.body-container {
    width: $xl;
    border-left: 2px black solid;
    border-right: 2px black solid;
    background-color: white;
    height: fit-content;
    @media only screen and (max-width: $xl) {
        border: none;
        width: 100vw;
    }
}

.suppress-link {
    text-decoration: none;
    color: black;
}

.list-padding {
    border-bottom: 2px solid black;
    padding: 0rem 10% 0rem 10%;
    @media only screen and (max-width: $sm) {
        padding: 0rem 5% 0rem 5%;
    }
}

.drop-shadow {
    filter: drop-shadow(black 1rem 1rem 1rem);
}

.list-divider{
    border-top: 1px black solid;
}
.list-divider-bottom{
    border-bottom: 1px black solid;
}

.round-top{
    border-radius: 1rem 1rem 0 0;
}
.round-bottom{
    border-radius: 0 0 1rem 1rem;
}
.round-top-bottom{
    border-radius: 1rem;
}

.no-border{
    border: none;
}

a {
    text-decoration: none;
    color: black;
}